<template>
  <div class="row csn-limits csn-user-account-section" id="user-account">
    <div class="col-md-12">
      <div class="container">
        <section>
          <h1>{{ t('title') }}</h1>
          <p>{{ t('description') }}</p>
        </section>
        <section v-if="IS_LIMIT_PENDING">
          <Loader />
        </section>

        <section v-else-if="LIMIT_ERROR">
          <h2>{{ t('account_limits') }}</h2>
          <Message :type="messageTypeEnum.FAILURE" :message="LIMIT_ERROR" />
        </section>
        <section v-else>
          <h2>{{ t('account_limits') }}</h2>

          <section v-if="isMobileScreen">
            <Message
              :type="messageTypeEnum.SUCCESS"
              :message="limitEditMessage"
              :hasBottom="true"
            />
            <div
              v-for="(limit, type) in LIMIT_DICTIONARY"
              :key="type"
              class="csn-limits-table-mobile-wrapper"
            >
              <table
                v-for="({
                  period,
                  amount,
                  amountWithCurrency,
                  remainingLimit,
                  validFrom,
                  validTo,
                },
                periodKey) in limit"
                :key="periodKey"
                class="csn-limits-table"
              >
                <tr>
                  <th>{{ t('type') }}</th>
                  <td class="csn-limits-table-type">
                    {{ type }}
                  </td>
                </tr>

                <tr>
                  <th>{{ t('period') }}</th>
                  <td>
                    {{ period }}
                  </td>
                </tr>

                <tr v-if="Number(amount)">
                  <th>{{ t('limit') }}</th>
                  <td>
                    {{
                      renderLimit({
                        amount,
                        amountWithCurrency,
                        type,
                      })
                    }}
                  </td>
                </tr>
                <tr v-else>
                  <th>{{ t('limit') }}</th>
                  <td>{{ t('no_limit') }}</td>
                </tr>

                <tr v-if="Number(amount) && type !== limitType.TIME">
                  <th>{{ t('remaining') }}</th>
                  <td>
                    {{ format(remainingLimit) || t('no_limit') }}
                  </td>
                </tr>

                <tr v-if="Number(amount)">
                  <th>{{ t('valid_from') }}</th>
                  <td>{{ validFrom || '-' }}</td>
                </tr>

                <tr v-if="Number(amount)">
                  <th>{{ t('valid_to') }}</th>
                  <td>{{ validTo || '-' }}</td>
                </tr>

                <tr>
                  <th>{{ t('options') }}</th>
                  <td>
                    <Route
                      :to="{
                        name: limitEditRoute,
                        params: { type },
                      }"
                    >
                      <img
                        src="@/assets/images/icons/general/Circle-Plus.svg"
                        alt="circle-plus"
                        class="csn-limits-table-option-icon"
                      />
                    </Route>
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <Message
            v-if="!isMobileScreen"
            :type="messageTypeEnum.SUCCESS"
            :message="limitEditMessage"
            :hasBottom="true"
          />
          <table v-if="!isMobileScreen" class="csn-user-account-table">
            <tr>
              <th>{{ t('type') }}</th>
              <th></th>
              <th></th>
              <th></th>
              <th>{{ t('options') }}</th>
            </tr>

            <tr v-for="(limit, type) in LIMIT_DICTIONARY" :key="type">
              <td class="csn-limits-table-type">{{ type }}</td>

              <td colspan="3">
                <table
                  class="csn-limits-subtable"
                  :class="{
                    'csn-limits-subtable-time': type === limitType.TIME,
                  }"
                >
                  <tr>
                    <th>
                      {{ t('period') }}
                    </th>
                    <th>
                      {{ t('limit') }}
                    </th>
                    <th v-if="type !== limitType.TIME">
                      {{ t('remaining') }}
                    </th>
                    <th>
                      {{ t('valid_from') }}
                    </th>
                    <th>
                      {{ t('valid_to') }}
                    </th>
                  </tr>

                  <tr
                    v-for="({
                      period,
                      amount,
                      amountWithCurrency,
                      remainingLimit,
                      validFrom,
                      validTo,
                    },
                    periodKey) in limit"
                    :key="periodKey"
                  >
                    <td>{{ period }}</td>
                    <td v-if="Number(amount)">
                      {{
                        renderLimit({
                          amount,
                          amountWithCurrency,
                          type,
                        })
                      }}
                    </td>
                    <td v-if="Number(amount) && type !== limitType.TIME">
                      {{ format(remainingLimit) || t('no_limit') }}
                    </td>
                    <td v-if="Number(amount)">{{ validFrom || '-' }}</td>
                    <td v-if="Number(amount)">{{ validTo || '-' }}</td>
                    <td colspan="4" v-else class="text-center">
                      {{ t('no_limit') }}
                    </td>
                  </tr>
                </table>
              </td>

              <td class="text-center">
                <Route
                  :to="{
                    name: limitEditRoute,
                    params: { type },
                  }"
                >
                  <span
                    class="glyphicon glyphicon-plus-sign csn-plus-icon"
                  ></span>
                </Route>
              </td>
            </tr>
          </table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import {
  USER_LIMITS,
  RouteName,
  LimitType,
  Module,
  LIMIT_DICTIONARY,
  IS_LIMIT_PENDING,
  IS_MOBILE_SCREEN,
  Type,
  Digit,
  EMPTY_STRING,
  MessageTypeEnum,
  LIMIT_ERROR,
  LIMIT_EDIT_MSG,
} from '@/constants'
import { formatNumberLocale, dest } from '@/helpers'

export default {
  name: USER_LIMITS,
  components: {
    Loader: () => import('@/components/Loader'),
    Route: () => import('@/components/Route'),
    Message: () => import('@/components/atoms/Message'),
  },
  data: () => ({
    errorMessage: EMPTY_STRING,
    limitEditMessage: EMPTY_STRING,
  }),
  computed: {
    ...mapState(Module.LIMIT, [
      LIMIT_DICTIONARY,
      LIMIT_ERROR,
      IS_LIMIT_PENDING,
    ]),
    ...mapGetters({ isMobileScreen: dest([Module.DEVICE, IS_MOBILE_SCREEN]) }),
    t() {
      return this.$createComponentTranslator(USER_LIMITS)
    },
    limitEditRoute: () => RouteName.USER_LIMIT_EDIT,
    limitType: () => LimitType,
    getHourPeriod: () => {
      return this.LIMIT_DICTIONARY
    },
    messageTypeEnum: () => MessageTypeEnum,
  },
  methods: {
    format(number) {
      return formatNumberLocale({ number })
    },
    rendersLimit({ limit, type }) {
      if (type === LimitType.DEPOSIT || type === LimitType.LOSS) {
        return true
      }

      if (typeof limit === Type.STRING) {
        return Number(limit.slice(Digit.ONE)) !== Digit.NOUGHT
      }

      if (typeof limit === Type.NUMBER) {
        return limit !== Digit.NOUGHT
      }

      return false
    },
    renderLimit({ amount, amountWithCurrency, type }) {
      if (type === LimitType.TIME) {
        const numberAmount = Number(amount)

        return numberAmount >= Digit.SIXTY
          ? (numberAmount / Digit.SIXTY).toFixed(Digit.NOUGHT)
          : numberAmount
      }

      return amountWithCurrency
    },
  },
  mounted() {
    this.limitEditMessage = sessionStorage.getItem(LIMIT_EDIT_MSG)
    sessionStorage.removeItem(LIMIT_EDIT_MSG)
  },
}
</script>
