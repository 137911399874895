var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row csn-limits csn-user-account-section",attrs:{"id":"user-account"}},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"container"},[_c('section',[_c('h1',[_vm._v(_vm._s(_vm.t('title')))]),_c('p',[_vm._v(_vm._s(_vm.t('description')))])]),(_vm.IS_LIMIT_PENDING)?_c('section',[_c('Loader')],1):(_vm.LIMIT_ERROR)?_c('section',[_c('h2',[_vm._v(_vm._s(_vm.t('account_limits')))]),_c('Message',{attrs:{"type":_vm.messageTypeEnum.FAILURE,"message":_vm.LIMIT_ERROR}})],1):_c('section',[_c('h2',[_vm._v(_vm._s(_vm.t('account_limits')))]),(_vm.isMobileScreen)?_c('section',[_c('Message',{attrs:{"type":_vm.messageTypeEnum.SUCCESS,"message":_vm.limitEditMessage,"hasBottom":true}}),_vm._l((_vm.LIMIT_DICTIONARY),function(limit,type){return _c('div',{key:type,staticClass:"csn-limits-table-mobile-wrapper"},_vm._l((limit),function(ref,periodKey){
              var period = ref.period;
              var amount = ref.amount;
              var amountWithCurrency = ref.amountWithCurrency;
              var remainingLimit = ref.remainingLimit;
              var validFrom = ref.validFrom;
              var validTo = ref.validTo;
return _c('table',{key:periodKey,staticClass:"csn-limits-table"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('type')))]),_c('td',{staticClass:"csn-limits-table-type"},[_vm._v(" "+_vm._s(type)+" ")])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('period')))]),_c('td',[_vm._v(" "+_vm._s(period)+" ")])]),(Number(amount))?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('limit')))]),_c('td',[_vm._v(" "+_vm._s(_vm.renderLimit({ amount: amount, amountWithCurrency: amountWithCurrency, type: type, }))+" ")])]):_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('limit')))]),_c('td',[_vm._v(_vm._s(_vm.t('no_limit')))])]),(Number(amount) && type !== _vm.limitType.TIME)?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('remaining')))]),_c('td',[_vm._v(" "+_vm._s(_vm.format(remainingLimit) || _vm.t('no_limit'))+" ")])]):_vm._e(),(Number(amount))?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('valid_from')))]),_c('td',[_vm._v(_vm._s(validFrom || '-'))])]):_vm._e(),(Number(amount))?_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('valid_to')))]),_c('td',[_vm._v(_vm._s(validTo || '-'))])]):_vm._e(),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('options')))]),_c('td',[_c('Route',{attrs:{"to":{
                      name: _vm.limitEditRoute,
                      params: { type: type },
                    }}},[_c('img',{staticClass:"csn-limits-table-option-icon",attrs:{"src":require("@/assets/images/icons/general/Circle-Plus.svg"),"alt":"circle-plus"}})])],1)])])}),0)})],2):_vm._e(),(!_vm.isMobileScreen)?_c('Message',{attrs:{"type":_vm.messageTypeEnum.SUCCESS,"message":_vm.limitEditMessage,"hasBottom":true}}):_vm._e(),(!_vm.isMobileScreen)?_c('table',{staticClass:"csn-user-account-table"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.t('type')))]),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.t('options')))])]),_vm._l((_vm.LIMIT_DICTIONARY),function(limit,type){return _c('tr',{key:type},[_c('td',{staticClass:"csn-limits-table-type"},[_vm._v(_vm._s(type))]),_c('td',{attrs:{"colspan":"3"}},[_c('table',{staticClass:"csn-limits-subtable",class:{
                  'csn-limits-subtable-time': type === _vm.limitType.TIME,
                }},[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.t('period'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.t('limit'))+" ")]),(type !== _vm.limitType.TIME)?_c('th',[_vm._v(" "+_vm._s(_vm.t('remaining'))+" ")]):_vm._e(),_c('th',[_vm._v(" "+_vm._s(_vm.t('valid_from'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.t('valid_to'))+" ")])]),_vm._l((limit),function(ref,periodKey){
                  var period = ref.period;
                  var amount = ref.amount;
                  var amountWithCurrency = ref.amountWithCurrency;
                  var remainingLimit = ref.remainingLimit;
                  var validFrom = ref.validFrom;
                  var validTo = ref.validTo;
return _c('tr',{key:periodKey},[_c('td',[_vm._v(_vm._s(period))]),(Number(amount))?_c('td',[_vm._v(" "+_vm._s(_vm.renderLimit({ amount: amount, amountWithCurrency: amountWithCurrency, type: type, }))+" ")]):_vm._e(),(Number(amount) && type !== _vm.limitType.TIME)?_c('td',[_vm._v(" "+_vm._s(_vm.format(remainingLimit) || _vm.t('no_limit'))+" ")]):_vm._e(),(Number(amount))?_c('td',[_vm._v(_vm._s(validFrom || '-'))]):_vm._e(),(Number(amount))?_c('td',[_vm._v(_vm._s(validTo || '-'))]):_c('td',{staticClass:"text-center",attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.t('no_limit'))+" ")])])})],2)]),_c('td',{staticClass:"text-center"},[_c('Route',{attrs:{"to":{
                  name: _vm.limitEditRoute,
                  params: { type: type },
                }}},[_c('span',{staticClass:"glyphicon glyphicon-plus-sign csn-plus-icon"})])],1)])})],2):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }